import React, { useState } from 'react';
import { Loader2, Lock, Mail, CheckCircle, XCircle, UserPlus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import Layout from "@/components/Layout";
import { useAuth } from "@/contexts/AuthContext";
import { useNavigate, Link } from "react-router-dom";

const RegisterPage = () => {
    const navigate = useNavigate();
    const { register } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(true);

    // Password validation states
    const [passwordValidation, setPasswordValidation] = useState({
        hasLength: false,
        hasNumber: false,
        hasCharacter: false,
        hasValidChars: true // This will be false if invalid special characters are used
    });

    // Validate password on change
    const validatePassword = (password) => {
        // Regular expressions for validation
        const numberRegex = /\d/;
        const characterRegex = /[a-zA-Z]/;
        const validCharsRegex = /^[a-zA-Z0-9@!#$]*$/; // Only allows letters, numbers, and @!#$

        setPasswordValidation({
            hasLength: password.length >= 8,
            hasNumber: numberRegex.test(password),
            hasCharacter: characterRegex.test(password),
            hasValidChars: validCharsRegex.test(password)
        });
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const isPasswordValid = () => {
        return passwordValidation.hasLength &&
            passwordValidation.hasNumber &&
            passwordValidation.hasCharacter &&
            passwordValidation.hasValidChars;
    };

    // Email validation function
    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    // Handle email change
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        // Only show validation error if user has typed something
        if (newEmail) {
            setIsEmailValid(validateEmail(newEmail));
        } else {
            setIsEmailValid(true); // Don't show error for empty email
        }
    };

    // Handle referral code change
    const handleReferralCodeChange = (e) => {
        setReferralCode(e.target.value.toUpperCase());
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            setError('Please fill in all fields');
            return;
        }

        if (!isPasswordValid()) {
            setError('Please ensure your password meets all requirements');
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            await register(email, password, referralCode);
            navigate('/rewrite');
        } catch (error) {
            console.error('Registration error:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const ValidationItem = ({ isValid, text }) => (
        <div className="flex items-center space-x-2">
            {isValid ? (
                <CheckCircle className="h-4 w-4 text-green-500" />
            ) : (
                <XCircle className="h-4 w-4 text-gray-300" />
            )}
            <span className={`text-sm ${isValid ? 'text-green-500' : 'text-gray-500'}`}>
                {text}
            </span>
        </div>
    );

    return (
        <Layout showSidebar={false}>
            <Card className="max-w-md mx-auto">
                <CardHeader>
                    <CardTitle className="text-center text-2xl">
                        Create Your Account
                    </CardTitle>
                </CardHeader>
                <CardContent className="space-y-6">
                    {error && (
                        <Alert variant="destructive">
                            <AlertTitle>Error</AlertTitle>
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    )}

                    <form onSubmit={handleRegister} className="space-y-4">
                        <div className="space-y-2">
                            <label className="text-sm font-medium">Email</label>
                            <div className="relative">
                                <Mail className="absolute left-3 top-3 h-4 w-4 text-gray-400"/>
                                <Input
                                    type="email"
                                    placeholder="Enter your email"
                                    className="pl-9"
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                            </div>
                            {!isEmailValid && email && (
                                <p className="text-sm text-red-500 mt-1">
                                    Please enter a valid email address
                                </p>
                            )}
                        </div>

                        <div className="space-y-2">
                            <label className="text-sm font-medium">Password</label>
                            <div className="relative">
                                <Lock className="absolute left-3 top-3 h-4 w-4 text-gray-400"/>
                                <Input
                                    type="password"
                                    placeholder="Create a password"
                                    className="pl-9"
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                            </div>
                            <div className="mt-2 space-y-1">
                                <ValidationItem
                                    isValid={passwordValidation.hasLength}
                                    text="At least 8 characters"
                                />
                                <ValidationItem
                                    isValid={passwordValidation.hasCharacter}
                                    text="Contains letters"
                                />
                                <ValidationItem
                                    isValid={passwordValidation.hasNumber}
                                    text="Contains numbers"
                                />
                                <ValidationItem
                                    isValid={passwordValidation.hasValidChars}
                                    text="Only letters, numbers, and @!#$ allowed"
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <label className="text-sm font-medium flex items-center">
                                Referral Code
                                <span className="text-gray-400 text-xs ml-2">(Optional)</span>
                            </label>
                            <div className="relative">
                                <UserPlus className="absolute left-3 top-3 h-4 w-4 text-gray-400"/>
                                <Input
                                    type="text"
                                    placeholder="Enter referral code"
                                    className="pl-9"
                                    value={referralCode}
                                    onChange={handleReferralCodeChange}
                                    maxLength={10}
                                />
                            </div>
                        </div>

                        <Button
                            type="submit"
                            className="w-full"
                            disabled={isLoading || !isPasswordValid()}
                        >
                            {isLoading ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin"/>
                                    Creating account...
                                </>
                            ) : (
                                'Create Account'
                            )}
                        </Button>

                        <div className="text-center text-sm">
                            <span className="text-gray-500">Already have an account? </span>
                            <Link
                                to="/login"
                                className="text-pink-500 hover:underline"
                            >
                                Login here
                            </Link>
                        </div>
                    </form>

                    <div className="mt-8 pt-4 border-t text-center text-sm text-gray-500">
                        <p>Copyright © 2024 StraightA</p>
                    </div>
                </CardContent>
            </Card>
        </Layout>
    );
};

export default RegisterPage;
