import React from 'react';
import AiRephraser from './pages/AiRephraser';
import LoginPage from './pages/auth/Login';
import {Routes, Route, BrowserRouter, Navigate} from "react-router-dom";
import {AuthProvider} from "@/contexts/AuthContext";
import AuthenticatedRoute from "@/routes/AuthenticatedRoute";
import RegisterPage from "@/pages/auth/RegisterPage";
import PublicRoute from "@/routes/PublicRoute";
import {Provider} from "react-redux";
import { store } from '@/redux/store';
import ProfilePage from "@/pages/UserProfile";
import AiDetection from "@/pages/AiDetection";
import LandingPage from "@/pages/LangdingPage";
import EmailVerification from "@/pages/EmailVerification";
import TurnitinDetection from "@/pages/TurnitinDetection";
import ForgetPasswordPage from "@/pages/auth/ForgetPassword";
import ResetPasswordPage from "@/pages/auth/ResetPassword";
import ReferenceRewrite from "@/pages/ReferenceRewrite";

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <AuthProvider>
                    <Routes>

                        {/* Public routes - redirect to home if already logged in */}
                        <Route
                            path="/login"
                            element={
                                <PublicRoute>
                                    <LoginPage />
                                </PublicRoute>
                            }
                        />
                        <Route
                            path="/register"
                            element={
                                <PublicRoute>
                                    <RegisterPage />
                                </PublicRoute>
                            }
                        />
                        {/* Email verification */}
                        <Route
                            path="/verify/email/:token"
                            element={
                                <EmailVerification />
                            }
                        />
                        <Route
                            path="/forget/password"
                            element={
                                <ForgetPasswordPage />
                            }
                        />
                        <Route
                            path="/reset/password/:token"
                            element={
                                <ResetPasswordPage />
                            }
                        />

                        {/* Authentication routes */}
                        <Route
                            path="/rewrite"
                            element={
                                <AuthenticatedRoute>
                                    <AiRephraser />
                                </AuthenticatedRoute>
                            }
                        />
                        {/*<Route*/}
                        {/*    path="/detection/ai"*/}
                        {/*    element={*/}
                        {/*        <AuthenticatedRoute>*/}
                        {/*            <AiDetection />*/}
                        {/*        </AuthenticatedRoute>*/}
                        {/*    }*/}
                        {/*/>*/}
                        <Route
                            path="/detection/turnitin"
                            element={
                                <AuthenticatedRoute>
                                    <TurnitinDetection />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/reference/rewrite"
                            element={
                                <AuthenticatedRoute>
                                    <ReferenceRewrite />
                                </AuthenticatedRoute>
                            }
                        />
                        <Route
                            path="/profile"
                            element={
                                <AuthenticatedRoute>
                                    <ProfilePage />
                                </AuthenticatedRoute>
                            }
                        />

                        {/* Catch all route - redirect to appropriate page */}
                        <Route
                            path="/"
                            element={
                                <LandingPage />
                            }
                        />

                        <Route
                            path="*"
                            element={<Navigate to="/" replace />}
                        />
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
