import React, {useState, useEffect, useMemo} from 'react';
import { FileText, Shield, Loader2, History, Download, FileDown } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import Layout from "@/components/Layout";
import api from "@/utils/api";
import { useCredit } from '@/hooks/useCredit';
import {createWordCounter} from "@/utils/wordCounter";

const SUCCESS_CODE = 10000;
const DETECTION_MAX_WORDS = 5000;

const TurnitinDetection = () => {
    const wordCounter = useMemo(() => createWordCounter(DETECTION_MAX_WORDS), []);

    const [inputText, setInputText] = useState('');
    const [taskName, setTaskName] = useState('');
    const [wordCount, setWordCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [detectionHistory, setDetectionHistory] = useState([]);
    const [isLoadingHistory, setIsLoadingHistory] = useState(true);
    const { refreshCredit } = useCredit();

    const handleTextChange = (e) => {
        const text = e.target.value;
        const newWordCount = wordCounter.getCurrentCount(text);

        if (wordCounter.isExceedingLimit(text)) {
            setError(`Text exceeds maximum word limit of ${DETECTION_MAX_WORDS} words`);
        } else {
            setError(null);
        }

        setInputText(text);
        setWordCount(newWordCount);
    };

    const fetchDetectionHistory = async () => {
        try {
            setIsLoadingHistory(true);
            const response = await api.get('/humanize/detection/history/');
            if (response.data.code === SUCCESS_CODE) {
                setDetectionHistory(response.data.body.ai_detection_history || []);
            }
        } catch (error) {
            console.error('Error fetching detection history:', error);
        } finally {
            setIsLoadingHistory(false);
        }
    };

    useEffect(() => {
        fetchDetectionHistory();
    }, []);

    const handleDetect = async () => {
        if (!inputText.trim()) {
            setError('Please enter text for detection.');
            return;
        }

        if (!taskName.trim()) {
            setError('Please enter a task name.');
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const response = await api.post('/humanize/detection/newSubmission/', {
                content: inputText,
                task_name: taskName
            });

            if (response.data.code !== SUCCESS_CODE) {
                throw new Error(response.data.message);
            }

            // Refresh history after successful detection
            await fetchDetectionHistory();
            refreshCredit();

            // Clear inputs after successful submission
            setInputText('');
            setTaskName('');
        } catch (error) {
            console.error('Error detecting AI content:', error);
            setError(error.message || 'Failed to analyze text. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownloadReport = async (uniqueId) => {
        try {
            const response = await api.get(`/humanize/detection/download/report/${uniqueId}`, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const filename = getFileNameFromContentDisposition(response)
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading report:', error);
        }
    };

    const handleDownloadInput = async (uniqueId) => {
        try {
            const response = await api.get(`/humanize/detection/download/input/${uniqueId}`, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const filename = getFileNameFromContentDisposition(response)
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading input:', error);
        }
    };

    const getFileNameFromContentDisposition = (response) => {
        console.log(response.headers)
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'file.txt';
        if (contentDisposition) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename;
    }

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const getStatusStyle = (status) => {
        switch (status) {
            case 3:
                return 'text-green-600 bg-green-50 border-green-200';
            case 1:
                return 'text-blue-600 bg-blue-50 border-blue-200';
            case 2:
                return 'text-red-600 bg-red-50 border-red-200';
            default:
                return 'text-gray-600 bg-gray-50 border-gray-200';
        }
    };

    const getScoreColor = (score) => {
        if (score == null) return 'text-yellow-500 font-semibold';
        if (score >= 80) return 'text-red-500 font-semibold';
        if (score >= 50) return 'text-yellow-500 font-semibold';
        return 'text-green-500 font-semibold';
    };

    const getSimilarityScoreColor = (score) => {
        if (score == null) return 'text-yellow-500 font-semibold';
        if (score >= 25) return 'text-red-500 font-semibold';
        if (score >= 15) return 'text-yellow-500 font-semibold';
        return 'text-green-500 font-semibold';
    };

    const getDisplayStatus = (statusCode) => {
        switch (statusCode) {
            case 1:
                return 'Processing'
            case 2:
                return 'Failed'
            case 3:
                return 'Completed'
        }
    };

    return (
        <Layout>
            <div className="max-w-6xl mx-auto space-y-6">
                <Card>
                    <CardHeader>
                        <CardTitle className="text-center text-2xl">
                            Turnitin Report
                        </CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-6">
                        <Alert variant="info" className="bg-pink-50">
                            <div className="px-4">
                                <AlertTitle className="text-base font-medium mb-2">How to Use</AlertTitle>
                                <AlertDescription>
                                    <ol className="list-decimal ml-4 space-y-2">
                                        <li className="pl-2">Each Turnitin Report takes <span
                                            className="font-medium">1</span> credit
                                        </li>
                                        <li className="pl-2">Name your task and paste your text below</li>
                                        <li className="pl-2">Click <span className="font-medium">Submit</span> to
                                            create the task. The Turnitin report will be ready to download within 12 hours.
                                        </li>
                                        <li className="pl-2">Download the detailed report once task is completed
                                        </li>
                                        <li className="pl-2">Please contact customer service if you need the report right away or it has been more than 12 hours.
                                        </li>
                                    </ol>
                                </AlertDescription>
                            </div>
                        </Alert>

                        {error && (
                            <Alert variant="destructive">
                                <AlertTitle>Error</AlertTitle>
                                <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        )}

                        <div className="space-y-4">
                            <div>
                                <div className="mb-4">
                                    <span className="font-semibold">Task Name</span>
                                    <input
                                        type="text"
                                        placeholder="Enter a name for this detection task..."
                                        value={taskName}
                                        onChange={(e) => setTaskName(e.target.value)}
                                        className="w-full mt-2 p-2 border rounded-md"
                                    />
                                </div>
                                <span className="font-semibold">Text to Analyze</span>
                                <Textarea
                                    placeholder="Enter text to analyze for AI detection..."
                                    value={inputText}
                                    onChange={handleTextChange}
                                    className="h-48 mt-2"
                                />
                                <div className="flex items-center justify-between mt-2">
                                    <div className="flex items-center space-x-2">
                                        <FileText className="h-4 w-4"/>
                                        <span className={`text-sm ${wordCount > DETECTION_MAX_WORDS ? 'text-red-500' : ''}`}>
                                            Word count: {wordCounter.getCountDisplay(inputText)}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-center">
                                <Button
                                    onClick={handleDetect}
                                    disabled={isLoading || !inputText.trim() || !taskName.trim() || wordCounter.isExceedingLimit(inputText)}
                                    className="px-8"
                                >
                                    {isLoading ? (
                                        <>
                                            <Loader2 className="mr-2 h-4 w-4 animate-spin"/>
                                            Analyzing...
                                        </>
                                    ) : (
                                        <>
                                            <Shield className="mr-2 h-4 w-4"/>
                                            Submit
                                        </>
                                    )}
                                </Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                <Card>
                    <CardHeader>
                        <CardTitle className="flex items-center space-x-2">
                            <History className="h-5 w-5"/>
                            <span>Detection History</span>
                        </CardTitle>
                    </CardHeader>
                    <CardContent>
                        {isLoadingHistory ? (
                            <div className="text-center py-4">Loading history...</div>
                        ) : detectionHistory.length === 0 ? (
                            <div className="text-center py-4 text-gray-500">
                                No detection history available
                            </div>
                        ) : (
                            <div className="relative w-full overflow-auto">
                                <table className="w-full caption-bottom text-sm">
                                    <thead className="[&_tr]:border-b">
                                    <tr className="border-b transition-colors hover:bg-muted/50">
                                        <th className="h-10 px-4 text-left align-middle font-medium text-muted-foreground">
                                            Date
                                        </th>
                                        <th className="h-10 px-4 text-left align-middle font-medium text-muted-foreground">
                                            Name
                                        </th>
                                        <th className="h-10 px-4 text-left align-middle font-medium text-muted-foreground">
                                            Status
                                        </th>
                                        <th className="h-10 px-4 text-left align-middle font-medium text-muted-foreground">
                                            AI %
                                        </th>
                                        <th className="h-10 px-4 text-left align-middle font-medium text-muted-foreground">
                                            Similarity %
                                        </th>
                                        <th className="h-10 px-4 text-left align-middle font-medium text-muted-foreground">
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {detectionHistory.map((item) => (
                                        <tr
                                            key={item.unique_id}
                                            className="border-b transition-colors hover:bg-muted/50"
                                        >
                                            <td className="p-4 align-middle">
                                                {formatDate(item.created_at)}
                                            </td>
                                            <td className="p-4 align-middle font-medium">
                                                {item.user_input_name}
                                            </td>
                                            <td className="p-4 align-middle">
                                                    <span className={`px-2 py-1 rounded-full text-xs font-medium border ${getStatusStyle(item.status)}`}>
                                                        {getDisplayStatus(item.status)}
                                                    </span>
                                            </td>
                                            <td className={`p-4 align-middle ${getScoreColor(item.ai_probability)}`}>
                                                {item.ai_probability}%
                                            </td>
                                            <td className={`p-4 align-middle ${getSimilarityScoreColor(item.similarity_probability)}`}>
                                                {item.similarity_probability}%
                                            </td>
                                            <td className="p-4 align-middle">
                                                <div className="flex space-x-2">
                                                    <Button
                                                        variant="ghost"
                                                        size="sm"
                                                        onClick={() => handleDownloadReport(item.unique_id)}
                                                        disabled={item.status !== 3}
                                                        className="h-8 px-2"
                                                    >
                                                        <Download className="h-4 w-4 mr-1" />
                                                        Report
                                                    </Button>
                                                    <Button
                                                        variant="ghost"
                                                        size="sm"
                                                        onClick={() => handleDownloadInput(item.unique_id)}
                                                        className="h-8 px-2"
                                                    >
                                                        <FileDown className="h-4 w-4 mr-1" />
                                                        Input
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </CardContent>
                </Card>
            </div>
        </Layout>
    );
};

export default TurnitinDetection;
