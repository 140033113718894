import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckCircle, XCircle, Loader2 } from 'lucide-react';
import api from "@/utils/api";

const EmailVerification = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [status, setStatus] = useState('loading');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const verifyEmail = async () => {
            if (!token) {
                setStatus('error');
                setErrorMessage('Verification token is missing');
                return;
            }

            try {
                const response = await api.post('/user/verification/email/', {
                    verify_token: token
                });

                if (response.data?.body?.is_email_verified === 1) {
                    setStatus('success');
                    setTimeout(() => {
                        navigate('/rewrite');
                    }, 3000);
                } else {
                    setStatus('error');
                    setErrorMessage('Email verification failed. Make sure the link is still valid and you have not already verified your email.');
                }
            } catch (error) {
                setStatus('error');
                setErrorMessage(error.response?.data?.message || 'Email verification failed');
            }
        };

        verifyEmail();
    }, [navigate, token]);

    return (
        <div className="min-h-screen bg-gray-900 flex items-center justify-center">
            <div className="bg-gray-900 p-8 rounded-lg max-w-md w-full mx-4">
                {status === 'loading' && (
                    <div className="text-center">
                        <Loader2 className="w-16 h-16 text-pink-500 animate-spin mx-auto mb-4" />
                        <h2 className="text-xl text-white font-semibold">Verifying your email...</h2>
                    </div>
                )}

                {status === 'success' && (
                    <div className="text-center">
                        <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
                        <h2 className="text-xl text-white font-semibold mb-2">Email Verified Successfully!</h2>
                        <p className="text-gray-400">Redirecting to Straight A...</p>
                    </div>
                )}

                {status === 'error' && (
                    <div className="text-center">
                        <XCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
                        <h2 className="text-xl text-white font-semibold mb-2">Verification Failed</h2>
                        <p className="text-gray-400">{errorMessage}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmailVerification;
