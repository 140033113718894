import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Loader2, Lock, CheckCircle, XCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import Layout from "@/components/Layout";
import api from "@/utils/api";

const ResetPasswordPage = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [token, setToken] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    // Add password validation states
    const [passwordValidation, setPasswordValidation] = useState({
        hasLength: false,
        hasNumber: false,
        hasCharacter: false,
        hasValidChars: true
    });

    useEffect(() => {
        // Extract token from URL path
        const pathSegments = location.pathname.split('/');
        const tokenFromUrl = pathSegments[pathSegments.length - 1];

        if (!tokenFromUrl) {
            setError('Invalid or missing reset token');
            return;
        }
        setToken(tokenFromUrl);
    }, [location]);

    // Validate password on change
    const validatePassword = (password) => {
        const numberRegex = /\d/;
        const characterRegex = /[a-zA-Z]/;
        const validCharsRegex = /^[a-zA-Z0-9@!#$]*$/;

        setPasswordValidation({
            hasLength: password.length >= 8,
            hasNumber: numberRegex.test(password),
            hasCharacter: characterRegex.test(password),
            hasValidChars: validCharsRegex.test(password)
        });
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const isPasswordValid = () => {
        return passwordValidation.hasLength &&
            passwordValidation.hasNumber &&
            passwordValidation.hasCharacter &&
            passwordValidation.hasValidChars;
    };

    const ValidationItem = ({ isValid, text }) => (
        <div className="flex items-center space-x-2">
            {isValid ? (
                <CheckCircle className="h-4 w-4 text-green-500" />
            ) : (
                <XCircle className="h-4 w-4 text-gray-300" />
            )}
            <span className={`text-sm ${isValid ? 'text-green-500' : 'text-gray-500'}`}>
                {text}
            </span>
        </div>
    );

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!password || !confirmPassword) {
            setError('Please fill in all fields');
            return;
        }

        if (!isPasswordValid()) {
            setError('Please ensure your password meets all requirements');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const response = await api.post('/user/password/reset/', {
                reset_password_token: token,
                reset_password: password
            });

            if (response.data.code === 10000) {
                setSuccess(true);
                // Redirect to login page after 2 seconds
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            } else {
                setError(response.data.message || 'Failed to reset password');
            }
        } catch (error) {
            console.error('Reset password error:', error);
            setError(error.response?.data?.message || 'An error occurred while resetting password');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Layout>
            <Card className="max-w-md mx-auto">
                <CardHeader>
                    <CardTitle className="text-center text-2xl">
                        Reset Your Password
                    </CardTitle>
                </CardHeader>
                <CardContent className="space-y-6">
                    {error && (
                        <Alert variant="destructive">
                            <AlertTitle>Error</AlertTitle>
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    )}

                    {success && (
                        <Alert className="bg-green-50 text-green-700 border-green-200">
                            <AlertTitle>Success</AlertTitle>
                            <AlertDescription>
                                Your password has been reset successfully.
                                Redirecting to login page...
                            </AlertDescription>
                        </Alert>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="space-y-2">
                            <label className="text-sm font-medium">New Password</label>
                            <div className="relative">
                                <Lock className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
                                <Input
                                    type="password"
                                    placeholder="Enter new password"
                                    className="pl-9"
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                            </div>
                            <div className="mt-2 space-y-1">
                                <ValidationItem
                                    isValid={passwordValidation.hasLength}
                                    text="At least 8 characters"
                                />
                                <ValidationItem
                                    isValid={passwordValidation.hasCharacter}
                                    text="Contains letters"
                                />
                                <ValidationItem
                                    isValid={passwordValidation.hasNumber}
                                    text="Contains numbers"
                                />
                                <ValidationItem
                                    isValid={passwordValidation.hasValidChars}
                                    text="Only letters, numbers, and @!#$ allowed"
                                />
                            </div>
                        </div>

                        <div className="space-y-2">
                            <label className="text-sm font-medium">Confirm Password</label>
                            <div className="relative">
                                <Lock className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
                                <Input
                                    type="password"
                                    placeholder="Confirm new password"
                                    className="pl-9"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                        </div>

                        <Button
                            type="submit"
                            className="w-full"
                            disabled={isLoading || !token || !isPasswordValid()}
                        >
                            {isLoading ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Resetting...
                                </>
                            ) : (
                                'Reset Password'
                            )}
                        </Button>
                    </form>

                    <div className="mt-8 pt-4 border-t text-center text-sm text-gray-500">
                        <p>Copyright © 2024 StraightA</p>
                    </div>
                </CardContent>
            </Card>
        </Layout>
    );
};

export default ResetPasswordPage;
