import React, { useState } from 'react';
import { Loader2, Mail } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import Layout from "@/components/Layout";
import { Link } from "react-router-dom";
import axios from 'axios';
import api from "@/utils/api";

const ForgetPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email) {
            setError('Please enter your email address');
            return;
        }

        setIsLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await api.post('/user/password/email/', {
                registered_email: email
            });

            if (response.data.code === 10000) {
                setSuccess(true);
            } else if (response.data.code === 40011) {
                setError(response.data.message || 'This email was not registered.');
            } else {
                setError(response.data.message || 'Failed to send reset email');
            }
        } catch (error) {
            console.error('Reset password error:', error);
            setError(error.response?.data?.message || 'An error occurred while sending reset email');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Layout>
            <Card className="max-w-md mx-auto">
                <CardHeader>
                    <CardTitle className="text-center text-2xl">
                        Reset Password
                    </CardTitle>
                </CardHeader>
                <CardContent className="space-y-6">
                    {error && (
                        <Alert variant="destructive">
                            <AlertTitle>Error</AlertTitle>
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    )}

                    {success && (
                        <Alert className="bg-green-50 text-green-700 border-green-200">
                            <AlertTitle>Success</AlertTitle>
                            <AlertDescription>
                                A password reset link has been sent to your email address.
                                Please check your inbox.
                            </AlertDescription>
                        </Alert>
                    )}

                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="space-y-2">
                            <label className="text-sm font-medium">Email</label>
                            <div className="relative">
                                <Mail className="absolute left-3 top-3 h-4 w-4 text-gray-400" />
                                <Input
                                    type="email"
                                    placeholder="Enter your registered email"
                                    className="pl-9"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>

                        <Button
                            type="submit"
                            className="w-full"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Sending...
                                </>
                            ) : (
                                'Send Reset Link'
                            )}
                        </Button>

                        <div className="text-center">
                            <Link
                                to="/login"
                                className="text-pink-500 hover:underline text-sm"
                            >
                                Back to Login
                            </Link>
                        </div>
                    </form>

                    <div className="mt-8 pt-4 border-t text-center text-sm text-gray-500">
                        <p>Copyright © 2024 StraightA</p>
                    </div>
                </CardContent>
            </Card>
        </Layout>
    );
};

export default ForgetPasswordPage;
