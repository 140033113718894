import React, { useState, useEffect } from 'react';
import { User, CreditCard, Mail, Calendar, Copy, Check, UserPlus, RefreshCw } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { useSelector } from 'react-redux';
import Layout from "@/components/Layout";
import api from "@/utils/api";

const ProfilePage = () => {
    const [copied, setCopied] = useState(false);
    const [isResending, setIsResending] = useState(false);
    const [resendSuccess, setResendSuccess] = useState(false);

    const handleCopyReferral = async () => {
        try {
            await navigator.clipboard.writeText(userProfile?.referral_code);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy text:', err);
        }
    };

    const handleResendVerification = async () => {
        try {
            setIsResending(true);
            await api.post('/user/verification/resend/email/');
            setResendSuccess(true);
            setTimeout(() => setResendSuccess(false), 3000);
        } catch (error) {
            console.error('Error resending verification:', error);
            setError('Failed to resend verification email. Please try again later.');
        } finally {
            setIsResending(false);
        }
    };

    const userProfile = useSelector((state) => state.user.profile);
    const [purchaseHistory, setPurchaseHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchPurchaseHistory();
    }, []);

    const fetchPurchaseHistory = async () => {
        try {
            setIsLoading(true);
            const response = await api.get('/subscription/credits/history');
            setPurchaseHistory(response.data.body.credit_histories || []);
        } catch (error) {
            console.error('Error fetching purchase history:', error);
            setError('Failed to load purchase history. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    return (
        <Layout>
            <div className="max-w-6xl mx-auto space-y-6">
                {/* User Info Card */}
                <Card>
                    <CardHeader>
                        <CardTitle className="flex items-center space-x-2">
                            <User className="h-5 w-5" />
                            <span>User Profile</span>
                        </CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-4">
                            <div className="flex items-center space-x-2">
                                <Mail className="h-4 w-4 text-gray-500"/>
                                <span className="font-medium">Email:</span>
                                <span>{userProfile?.email}</span>
                                {userProfile?.is_email_verified === 0 && (
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={handleResendVerification}
                                        disabled={isResending || resendSuccess}
                                        className="ml-2"
                                    >
                                        {resendSuccess ? (
                                            <Check className="h-4 w-4 text-green-500 mr-2"/>
                                        ) : (
                                            <RefreshCw className={`h-4 w-4 mr-2 ${isResending ? 'animate-spin' : ''}`}/>
                                        )}
                                        {resendSuccess ? 'Sent!' : isResending ? 'Sending...' : 'Resend verification'}
                                    </Button>
                                )}
                            </div>
                            <div className="flex items-center space-x-2">
                                <CreditCard className="h-4 w-4 text-gray-500"/>
                                <span className="font-medium">Available Credits:</span>
                                <span className="text-lg font-semibold text-pink-600">
                                    {userProfile?.credit || 0}
                                </span>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-2">
                                    <UserPlus className="h-4 w-4 text-gray-500"/>
                                    <span className="font-medium">Referral Code:</span>
                                    <div className="flex items-center space-x-2">
                                        <code className="bg-gray-100 px-2 py-1 rounded text-sm">
                                            {userProfile?.referral_code || 'N/A'}
                                        </code>
                                        <Button
                                            variant="ghost"
                                            size="sm"
                                            onClick={handleCopyReferral}
                                            className="p-1 h-8 w-8"
                                            title="Copy referral code"
                                            disabled={!userProfile?.referral_code}
                                        >
                                            {copied ? (
                                                <Check className="h-4 w-4 text-green-500"/>
                                            ) : (
                                                <Copy className="h-4 w-4 text-gray-500"/>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                                <Button
                                    variant="outline"
                                    onClick={() => window.open('https://afh1lmpx0eddz0cs.mikecrm.com/zsjs2X3', '_blank')}
                                >
                                    <CreditCard className="mr-2 h-4 w-4"/>
                                    Purchase Credits
                                </Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                {/* Purchase History Card */}
                <Card>
                    <CardHeader>
                        <CardTitle className="flex items-center space-x-2">
                            <CreditCard className="h-5 w-5"/>
                            <span>Credit Purchase History</span>
                        </CardTitle>
                    </CardHeader>
                    <CardContent>
                        {error ? (
                            <Alert variant="destructive">
                                <AlertTitle>Error</AlertTitle>
                                <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        ) : isLoading ? (
                            <div className="text-center py-4">Loading purchase history...</div>
                        ) : purchaseHistory.length === 0 ? (
                            <div className="text-center py-4 text-gray-500">
                                No purchase history available
                            </div>
                        ) : (
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Date</TableHead>
                                        <TableHead>Credits</TableHead>
                                        <TableHead>Order Description</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {purchaseHistory.map((purchase) => (
                                        <TableRow key={purchase.id}>
                                            <TableCell className="text-gray-600">
                                                {formatDate(purchase.created_at)}
                                            </TableCell>
                                            <TableCell className="text-pink-600 font-medium">
                                                {purchase.credits}
                                            </TableCell>
                                            <TableCell className="font-mono text-sm text-gray-600">
                                                {purchase.order_description}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </CardContent>
                </Card>
            </div>
        </Layout>
    );
};

export default ProfilePage;
