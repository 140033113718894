// src/utils/textUtils.js

export const MAX_CHARS_PER_WORD = 30;
export const DEFAULT_MAX_WORD_COUNT = 500;

/**
 * Counts words in a text with special handling for long strings and camelCase
 * @param {string} text - The input text to count words from
 * @returns {number} The number of words counted
 */
export const countWords = (text) => {
    if (!text || !text.trim()) return 0;

    // Normalize whitespace and trim the text
    const normalizedText = text.trim().replace(/\s+/g, ' ');
    const words = normalizedText.split(' ');

    return words.reduce((acc, word) => {
        if (!word) return acc;

        // Handle long words that might be multiple words stuck together
        if (word.length > MAX_CHARS_PER_WORD) {
            // 1. Split by capital letters (CamelCase)
            // "HelloWorld" -> ["Hello", "World"]
            const camelCaseSplit = word.split(/(?=[A-Z])/g);

            // 2. Split by numbers and punctuations
            // "thisIs1Word" -> ["thisIs", "1", "Word"]
            const withNumbersSplit = camelCaseSplit
                .flatMap(part => part.split(/(?<=\d)(?=[a-zA-Z])|(?<=[a-zA-Z])(?=\d)/g));

            // 3. Handle any remaining long parts by breaking them into chunks
            const finalParts = withNumbersSplit.flatMap(part => {
                if (part.length > MAX_CHARS_PER_WORD) {
                    // Break very long strings into chunks of MAX_CHARS_PER_WORD
                    const chunks = [];
                    for (let i = 0; i < part.length; i += MAX_CHARS_PER_WORD) {
                        chunks.push(part.slice(i, i + MAX_CHARS_PER_WORD));
                    }
                    return chunks;
                }
                return [part];
            });

            // Add the number of parts to our word count
            return acc + finalParts.length;
        }

        // If it's a normal word, just add 1 to our count
        return acc + 1;
    }, 0);
};

/**
 * Creates a word count validator with a specific maximum
 * @param {number} maxWords - Maximum number of words allowed
 * @returns {Object} Object containing utility functions for the specified limit
 */
export const createWordCounter = (maxWords = DEFAULT_MAX_WORD_COUNT) => {
    return {
        /**
         * Checks if text exceeds maximum word count
         * @param {string} text - The input text to check
         * @returns {boolean} True if text exceeds max word count
         */
        isExceedingLimit: (text) => {
            return countWords(text) > maxWords;
        },

        /**
         * Gets a formatted string of word count and max word count
         * @param {string} text - The input text
         * @returns {string} Formatted string like "123 / 500"
         */
        getCountDisplay: (text) => {
            return `${countWords(text)} / ${maxWords}`;
        },

        /**
         * Gets just the current word count
         * @param {string} text - The input text
         * @returns {number} Current word count
         */
        getCurrentCount: (text) => {
            return countWords(text);
        },

        /**
         * Gets the maximum word count
         * @returns {number} Maximum word count
         */
        getMaxCount: () => maxWords
    };
};
